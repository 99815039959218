<template>
   <main>
		<HeaderTab 
            :title="componentTitle" 
        />
        <div id="content">
            <ShutterPanel />
        </div>
    </main>
</template>


<script type="text/javascript">
    import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import ShutterMvt from '@/mixins/shutters-manager/Mouvements.js'
	import Mouvements from "@/mixins/Mouvements.js";
	import Lieux from "@/mixins/Lieux.js";

	export default {
        name: "MouvementManagement",
        mixins: [Shutter, ShutterMvt, ShutterTriggers, Lieux, Mouvements],
		data () {
			return {
                /* Main view params */
                componentTitle: '',

                mouvement_id: null,
				horse_ids: null, /* Array d'ids de chevaux (transmis dans les routes sous forme de liste séparée par des virgules) */
				checked_horses: [],
				from_asset: '', // vue / composant depuis lequelle la route a été push pour arriver jusqu'ici
				actual_component_name: '',
				triggered_list: []
			}
        },
        mounted() {
			// On récupère les éventuels params
			this.mouvement_id = this.$route.params.mouvement_id
			this.horse_ids = (this.$route.params.horse_id !== undefined ? String(this.$route.params.horse_id).split(",") : [] )
			this.checked_horses = [...this.horse_ids]
			this.from_asset = this.$route.params.from
			this.actual_component_name = this.$route.name
			this.first_mouv = this.$route.params.first_mouv

			this.init_component()
		},
        methods: {
            init_component() {
                if(this.horse_ids.length > 0) {
                	this.openMvtAddForm(this.horse_ids)
                    this.componentTitle = this.getTrad('mouvement.ajouter_mouvement')
                    if(this.mouvement_id)
                    	this.componentTitle = this.getTrad('mouvement.modifier_mouvement')
				} else {
					this.openHorseSelection()
                    this.componentTitle = this.getTrad('mouvement.ajouter_mouvement')
				}
            },

            async valid_form(data) {
				const horses = data.find(data => data.type == 'horses')

				const success = await this.processMouvOperations(data, horses.horses, this.mouvement_id, this.hasNoPreviousMouv)

				if(!success) {
					this.failureToast('toast.info_save_failed')
					return
				}

				this.successToast('toast.info_save_succes')
				this.getTrigger(data)
			},

			genericReturnData(data) {
				// Si les données viennent du formulaire d'ajout direct de mouvement, on enregistre un mouvement avec ces infos
				if(this.actual_component_name == 'WriteMouvement' || this.actual_component_name == "mouvementAjout") {
					this.valid_form(data)
				}
			},

			getTrigger(data) {
				const mouvement = data.find(data => data.type == 'mouvement')
				const horses = data.find(data => data.type == 'horses')

				this.setupActPropositionMouvement(horses.horses, mouvement.mouvement_date, mouvement.mouvement_lieu, (result) => {
					result.forEach(triggered => {
                        this.triggered_list.push({
                            type: triggered.type,
                            horses: triggered.horses,
                            date: triggered.date
                        })
                    })

                    this.shutterPanel().close('proposition-acte')
                    this.shutterPanel().close('horse-selection')
                    this.nextTrigger()
                },() => {
                    this.redirectPostSave()
                })
			},

			nextTrigger() {
				if(this.triggered_list.length > 0) {
                    const triggered = this.triggered_list[0]
                    this.triggered_list.shift()
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.triggered_list)
                } else {
                    this.redirectPostSave()
                }
			},

			redirectPostSave() {
				if(this.from_asset == 'horseAjout' || this.from_asset == 'HorseCreation') {
					this.$router.push({ name: 'horseFiche', params: { horse_id: this.checked_horses.join(',') } })
				}
				else if(this.checked_horses && this.from_asset == 'HorseFicheMouvements')
					this.$router.push({ name: 'HorseFicheMouvements', params: { horse_id: this.checked_horses.join(',') } })
				else
					this.$router.push({ name: 'mouvementListe' })
			}
        },

        computed: {
			hasNoPreviousMouv() {
				const whitelist = ["horseAjout", "HorseCreation"]
				if(whitelist.indexOf(this.from_asset) > -1) {
					return true
				}
				if(this.first_mouv) {
					return true
				}
				return false
			}
        },

        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ShutterPanel
		},
    }
</script>
